@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap');

/* roboto-regular - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto'), local('Roboto-Regular'),
		url('./fonts/roboto-v20-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - latin */
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	src: local('Roboto Italic'), local('Roboto-Italic'),
		url('./fonts/roboto-v20-latin-italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/roboto-v20-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url('./fonts/roboto-v20-latin-500.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/roboto-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('./fonts/montserrat-v15-latin-600.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('./fonts/montserrat-v15-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'Marianne';
	src: url('./fonts/Marianne-Thin.woff2') format('woff2'),
		url('./fonts//Marianne-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url('./fonts//Marianne-Bold.woff2') format('woff2'),
		url('./fonts//Marianne-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url('./fonts//Marianne-Light.woff2') format('woff2'),
		url('./fonts//Marianne-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url('./fonts//Marianne-ExtraBold.woff2') format('woff2'),
		url('./fonts//Marianne-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url('./fonts//Marianne-Regular.woff2') format('woff2'),
		url('./fonts//Marianne-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	display: swap;
}

@font-face {
	font-family: 'Marianne';
	src: url('./fonts//Marianne-Medium.woff2') format('woff2'),
		url('./fonts//Marianne-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	display: swap;
}

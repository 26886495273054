.feedback-page {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	background: var(--lightestColor);
	border-radius: 0.9rem;
	padding: 0.6rem 1rem;
	margin: 1rem 0;
}
.feedback-page button.link-button {
	margin: 0 0.6rem;
}

@media (min-width: 1200px) {
	.feedback-page .feedbackButtons {
		display: inline;
	}
}

.zammad-form > .form-group:nth-of-type(2) {
	display: none;
}
.zammad-form > .btn[type='submit'] {
	display: inline-block;
	padding: 0.4rem 0.8rem;
	color: inherit;
	text-decoration: none;
	border: 1px solid;
	/* outline: none; */
	line-height: initial;
	display: inline-block;
	border-radius: 0.3rem;
	transition: all 0.15s;
	text-align: center;
	text-transform: uppercase;

	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	cursor: pointer;
}

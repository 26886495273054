.ui__.checkbox {
	cursor: pointer;
	display: inline-block;
	position: relative;
	margin: 0;
	width: 1em;
	line-height: 1em;
	height: 1em;
	-webkit-tap-highlight-color: transparent;
	transform: translate3d(0, 0, 0);
	outline: none !important;
}
.ui__.checkbox:before {
	content: '';
	position: absolute;
	top: -0.6em;
	left: -0.6em;
	width: 2.2em;
	height: 2.2em;
	border-radius: 50%;
	background: rgba(34, 50, 84, 0.03);
	opacity: 0;
	transition: opacity 0.2s ease;
}
.ui__.checkbox svg {
	position: relative;
	z-index: 1;
	fill: none;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke: #c8ccd4;
	stroke: var(--color);
	stroke-width: 1.5px;
	transform: translate3d(0, 0, 0);
	transition: all 0.2s ease;
}
.ui__.checkbox svg path {
	stroke-dasharray: 60;
	stroke-dashoffset: 0;
}
.ui__.checkbox svg polyline {
	stroke-width: 3px;
	stroke-dasharray: 22;
	stroke-dashoffset: 66;
}
.ui__.checkbox:hover:before,
.ui__.checkbox:focus:before {
	opacity: 1;
}
.ui__.checkbox:hover svg {
	stroke: var(--color);
}
.ui__.checkbox-input:checked + label .ui__.checkbox svg {
	stroke: white;
}
.ui__.checkbox-input:checked + label .ui__.checkbox svg polyline {
	stroke-dashoffset: 42;
	transition: all 0.1s linear;
	transition-delay: 0.075s;
}

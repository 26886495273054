.ui__.button.static {
	animation: none !important;
}
.ui__.button:disabled,
.ui__.button.disabled {
	opacity: 0.5;
	cursor: not-allowed;
	/* pointer-events: none; */
}

.ui__.button {
	font-size: 1.2rem;
	display: inline-block;
	padding: 0.6rem 1.2rem;
	text-decoration: none;
	/* outline: none; */
	line-height: initial;
	display: inline-block;
	border-radius: 0.3rem;
	transition: all 0.15s;
	text-align: center;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	cursor: pointer;
}

.ui__.button.plain {
	background-image: linear-gradient(
		50deg,
		rgba(39, 69, 195, 0.87) 5%,
		rgb(41, 117, 209) 50%,
		rgba(41, 117, 209, 0.9) 55%
	);
	background-image: linear-gradient(
		50deg,
		var(--darkestColor) -50%,
		var(--color) 30%
	);

	color: white;
}
.ui__.button:not(:disabled):not(.disabled):not(.simple):hover,
.ui__.button.selected {
	background-position-x: 0%;
	border-color: white;
	color: white;
}

.ui__.button.simple,
.ui__.button.simple.small {
	border: none;
	background: transparent;
	padding-left: 0;
	padding-right: 0;
}
.ui__.button.simple:hover,
.ui__.button.simple.selected {
	opacity: 0.8;
}
.ui__.button.simple:not(:last-of-type) {
	margin-right: 0.6em;
}
.ui__.button.cta {
	margin: 1rem 0;
	display: inline-block;
	align-self: center;
	font-size: 120%;
	padding: 0.9rem 1.8rem;
	font-weight: 600;
}

.ui__.button.fffcta {
	display: flex;
    align-items: center;
	width: 150px;
	height: 150px;
	font-size: 22px;
	line-height: 22px;
	letter-spacing: 1.26px;
	border-radius: 75px;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	@media (max-width: 800px) {
		width: 40px;
	}
}

.ui__.button.fff {
	font-size: 15px;
	letter-spacing: 3px;
	padding: 10px 20px;
	border-radius: 80px;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
}

.ui__.button.fff_white {
	background-color: white;
	border: 1px solid var(--purple);
	color: black;
}

.ui__.button.fff_white:hover {
	background-color: var(--gold) !important;
	color: white !important;
}

.ui__.button.fff_blue {
	background-color: var(--purple);
	color: white;
}

.ui__.button.fff_blue:hover {
	background-color: var(--lightPurple) !important;
}



.ui__.link-button,
.ui__.dashed-button,
.ui__.text-button {
	border: none;
	display: inline;
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
	margin: 0;
}
.ui__.link-button img[src*='twemoji.maxcdn'],
.ui__.dashed-button img[src*='twemoji.maxcdn'],
.ui__.text-button img[src*='twemoji.maxcdn'] {
	margin-right: 0.2rem !important;
	margin-left: 0.2rem !important;
}
.ui__.link-button,
.ui__.text-button {
	text-decoration: none;
	color: inherit;
	text-align: left;
	padding: 0;
}
.ui__.link-button {
	text-decoration: underline;
	text-underline-offset: 4px;
	color: rgb(41, 117, 209);
	color: var(--color);
	color: white;
}
.ui__.link-button.active {
	font-weight: bold;
}

.ui__.dashed-button::before {
	padding: 0.8rem 0;
	display: block;
}
.ui__.dashed-button {
	border-bottom: 1px dashed;
	border-color: rgb(41, 117, 209);
	border-color: var(--color);
	padding: 0.15em 0em;
}

.ui__.link-button:not(:disabled):not(.disabled):hover,
.ui__.text-button:not(:disabled):not(.disabled):hover,
.ui__.dashed-button:not(:disabled):not(.disabled):hover,
.ui__.link-button:not(:disabled):not(.disabled).selected,
.ui__.text-button:not(:disabled):not(.disabled).selected,
.ui__.dashed-button:not(:disabled):not(.disabled).selected {
	opacity: 0.8;
}

.ui__.push-right.button:not(:disabled):not(.disabled):hover {
	opacity: 0.8;
	transform: translateX(3px);
}
.ui__.button.push-left:not(:disabled):not(.disabled):hover {
	opacity: 0.8;
	transform: translateX(-3px);
}

.answer-list button.answer:hover {
	opacity: 0.8;
}

.ui__.push-right.button:active {
	animation: push-button-right 0.1s ease-out alternate-reverse 2;
}
.ui__.button.push-left:active {
	animation: push-button-left 0.1s ease-out alternate-reverse 2;
}

.ui__.small.button {
	width: inherit;
	font-size: 90%;
	line-height: 1.2rem;
	transition: all 0.08s;
	padding: 0.4rem 0.8rem;
	border-radius: 0.3rem;
}

.ui__.button-choice {
	padding-top: 1rem;
	color: inherit;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	position: relative;
	min-width: 14rem;
	margin-right: 1rem;
	text-decoration: none;
}

.ui__.button-choice--soon {
	border-color: lightgray;
	color: gray;
	position: relative;
	opacity: 0.8;
	pointer-events: none;
	cursor: default;
	justify-content: flex-end;
}

.ui__.button-choice--soon img {
	filter: saturate(0%);
}

@keyframes push-button-down {
	from {
		transform: translate3d(0, 3px, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes push-button-right {
	from {
		transform: translate3d(5px, 0, 0);
	}
	to {
		transform: translate3d(3px, 0, 0);
	}
}

@keyframes push-button-left {
	from {
		transform: translate3d(-5px, 0, 0);
	}
	to {
		transform: translate3d(-3px, 0, 0);
	}
}

.ui__.button.attention {
	--border-width: 3px;
	position: relative;
	border-radius: var(--border-width);
	border: none;
}

.ui__.button.attention::after {
	position: absolute;
	content: '';
	top: calc(-1 * var(--border-width));
	left: calc(-1 * var(--border-width));
	z-index: -1;
	width: calc(100% + var(--border-width) * 2);
	height: calc(100% + var(--border-width) * 2);
	background: linear-gradient(
		60deg,
		hsl(224, 85%, 66%),
		hsl(269, 85%, 66%),
		hsl(314, 85%, 66%),
		hsl(359, 85%, 66%),
		hsl(44, 85%, 66%),
		hsl(89, 85%, 66%),
		hsl(134, 85%, 66%),
		hsl(179, 85%, 66%)
	);
	background-size: 300% 300%;
	background-position: 0 50%;
	border-radius: calc(2 * var(--border-width));
	animation: moveGradient 4s alternate infinite;
}

@keyframes moveGradient {
	50% {
		background-position: 100% 50%;
	}
}

.ui__.card {
	padding-left: 1rem;
	padding-right: 1rem;
	background: white;
	will-change: box-shadow;
	user-select: text;
	transition: box-shadow 0.15s, border-color 0.15s;
}

.ui__.card.disabled .ui__.button.simple,
.ui__.card.disabled a {
	/* opacity: 0.7; */
	color: var(--grayColor);
}

@media screen {
	.ui__.card.plain small,
	.ui__.card.plain .notice {
		opacity: 0.9;
		color: white;
	}

	.ui__.card.plain {
		color: white;
		color: var(--textColor);
		background: linear-gradient(60deg, #215da6 0%, #297da1 100%);
		background: linear-gradient(60deg, var(--darkColor) 0%, var(--color) 100%);
	}

	.ui__.card.disabled .ui__.card.plain,
	.ui__.card.disabled.plain {
		background: linear-gradient(
			60deg,
			var(--grayColor) -40%,
			var(--grayColor) 70%
		);
		opacity: 0.7;
	}
	.ui__.card.plain .h,
	.ui__.card.plain h1,
	.ui__.card.plain h2,
	.ui__.card.plain h3,
	.ui__.card.plain h4,
	.ui__.card.plain h5,
	.ui__.card.plain h6,
	.ui__.label a,
	.ui__.card.plain a,
	.ui__.label .link-button,
	.ui__.card.plain .link-button {
		color: white;
		color: var(--textColor);
	}

	.ui__.card.plain .targetInput {
		border-color: white;
		border-color: var(--textColor);
		color: white;
		color: var(--textColor);
	}
}

.ui__.card.light {
	background: linear-gradient(
		60deg,
		var(--lighterColor) -100%,
		var(--lightestColor) 100%
	);
}

.ui__.card.disabled img {
	filter: saturate(0);
}
.ui__.card.content {
	padding: 1rem;
	padding-top: 0.1rem;
}

.ui__.card.plain a:hover {
	text-decoration: underline;
}
.ui__.card.colored {
	box-shadow: 0 1px 3px 0 var(--color), 0 0 0 1px var(--color);
}

.ui__.interactive.card {
	user-select: text;
	-webkit-user-drag: none;
}
.ui__.interactive.card:hover {
	box-shadow: 0px 2px 4px -1px rgba(var(--rgbColor), 0.2),
		0px 4px 5px 0px rgba(var(--rgbColor), 0.14),
		0px 1px 10px 0px rgba(var(--rgbColor), 0.12);
	opacity: inherit !important;
}
.ui__.ui__.interactive.card:active {
	animation: push-button-down 0.1s ease-out alternate-reverse 2;
}

.ui__.card.box {
	flex: 0.3;
	max-width: 18rem;
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: 0.6rem;
	padding: 1rem;
	text-decoration: none;
	align-items: center;
	color: inherit;
	font-size: inherit !important;
}
.ui__.card.box.thinner {
	max-width: 15rem;
}
.ui__.card.interactive.light-border:not(.selected):hover {
	border-color: var(--purple);
	/* border-color: var(--lightColor); */
}
.ui__.card.box .ui__.box-icon > img {
	margin: 0.4rem !important;
	transform: scale(1.5) translateY(0.1rem);
}

.ui__.card.box .ui__.big.box-icon > img {
	transform: scale(2.3) translateY(0.2rem);
	margin: 0.6rem !important;
}

.ui__.card.box.inverted-colors {
	background: #4e575b;
}

.ui__.card.box.inverted-colors * {
	color: #f3f3f3;
}

.ui__.box-container {
	display: grid;
	justify-items: stretch;
}
.ui__.box-container:not(.full-width) {
	margin: 0 -0.6rem;
}
.ui__.small.box-container {
	grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 400px) {
	.ui__.card.box {
		max-width: 100%;
	}
}

@media (min-width: 400px) {
	.ui__.small.box-container {
		grid-template-columns: repeat(3, 1fr);
	}
	.ui__.box-container {
		grid-template-columns: repeat(2, 1fr);
	}
	.ui__.large.box-container {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (min-width: 700px) {
	.ui__.large.box-container {
		grid-template-columns: repeat(2, 1fr);
	}
	.ui__.small.box-container {
		grid-template-columns: repeat(4, 1fr);
	}
	.ui__.box-container {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 1000px) {
	.ui__.large.box-container {
		grid-template-columns: repeat(2, 1fr);
	}
	.ui__.full-width.small.box-container {
		grid-template-columns: repeat(5, auto);
	}
	.ui__.full-width.box-container {
		--padding: calc((100vw - 1300px) / 2);
		grid-template-columns: repeat(4, auto);
		padding-right: var(--padding);
		padding-left: var(--padding);
		justify-content: center;
	}
}

.ui__.card .ui__.notice {
	flex: 1;
}

.ui__.card.interactive.selected {
	border: 4px solid white;
	background: var(--purple);
}

.ui__.card.interactive.selected label {
	color: white;
}

.ui__.card.inactive {
	border: 4px solid grey;
	background: #2da44e0f;
	filter: grayscale(50%);
}
